.navFrame {
  height: 8vh;
  display: inline-flex;
  padding-left: 1em;
  padding-right: 1em;
  background: var(--greenPants);
}

.luigiMotaName {
  position: relative;
  max-width: 90vw;
  max-height: 20vh;
  padding-top: 1em;
}

/* navbuttons */
.navButtons {
  display: flex;
  position: absolute;
  text-align: right;
  padding-right: 2em;
  padding-top: 1em;
  right: 0;
  font-family: acumin-pro, sans-serif;
  font-style: italic;
}

a:link {
  text-decoration: none;
}

h3 {
  color: white;
  bottom: 0;
}

.work-n:hover,
.about-n:hover {
  color: black;
}

.work-n {
  padding-right: 1em;
}

/* landing page content */
.contentFrame {
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 1em;
  margin: 1em;
  background-color: var(--greenPants);
  border: solid black .35em;
  height: 85vh;
}

.landingPage {
  width: 100vw;
  margin: auto;
  background-color: var(--greenPants);
}

.roles {
  padding-left: 1em;
  font-family: 'Courier New', Courier, monospace, sans-serif;
  font-size: larger;
  color: white;
  font-weight: bolder;
}

.blackText {
  color: black;
}

.headShot {
  max-height: 60vh;
  max-width: 60vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: .9;
}

.blackDashes,
.whiteDashes {
  position: relative;
  width: 10vw;
}