.workPage {
  width: 100vw;
  display: block;
  background-color: var(--greenPants);
  position: relative;
  border-top: solid white;
  border-bottom: solid white;
}

.simDetails {
  display: block;
  font-style: italic;
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 100;
  max-width: 30vw;
  font-size: 3em;
  padding: 2em;
  line-height: 1em;
}

/*
chainfighter interactions
*/
.cf-interaction {
  display: grid;
  justify-content: center;
  justify-items: center;
}

.cf-button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
}

.cf-google-play {
  width: 5em;
}

/*
flashcard.app interactions
*/

.fca-button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
}

/*
reset the phone mockup
*/

.reset-button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
}

.cf-button:active,
.fca-button:active,
.reset-button:active {
  color: var(--greenPants);
}

/* phone sim */
.phoneSim {
  max-width: 30vw;
}

.cf-video {
  display: none;
  border-radius: 1.1em;
}

.container {
  font-family: "20dbregular";
  height: 100vh;
  display: grid;
  grid-template-columns: 23.1vw 5.5vw 2.1vw 11vw 58.3vw;
  grid-template-rows: 59.3vh 10vh 2.1vh 5.5vh 23.1vh;
  grid-template-areas:
    "B B B B A"
    "C F F E A"
    "C F F E A"
    "C G H E A"
    "C D D D A";
  background-position: right;
}

.item {
  justify-content: center;
  align-items: center;
  padding-right: 1em;
  border-style: 3px;
}

.item.a {
  grid-area: A;
  display: flex;
  justify-content: center;
  border-left: solid white;
}

.item.b {
  grid-area: B;
  background-color: black;
  border-bottom: solid white;
  image-orientation: flip;
  background-repeat: no-repeat;
  background-position: right;
}

.item.c {
  grid-area: C;
  background-color: white;
  border-right: solid black;
}

.item.d {
  grid-area: D;
  justify-content: right;
  display: flex;
  background-color: black;
  background-size: contain;
}

.item.e {
  grid-area: E;
  justify-content: right;
  display: flex;
  background-color: white;
  border-left: solid black;
}

.item.f {
  grid-area: F;
  border-bottom: solid white;
}

.item.g {
  grid-area: G;
  background-color: var(--greenPants);
  border-bottom: solid white;
  border-right: solid black;
  background-size: contain;
}

.item.h {
  background-color: var(--greenPants);
  grid-area: H;
  border-bottom: solid white;
  border-right: black;
}

@media only screen and (max-width: 850px) {

  .workPage {
    width: 100vw;
    background-color: var(--greenPants);
  }

  /*
  phone sim and video
  */
  .simDetails {
    display: inline;
    text-align: center;
    font-style: italic;
    max-width: 100vw;
    font-family: acumin-pro, sans-serif;
    font-weight: 100;
    font-size: 2em;
    padding: 1em;  justify-items: center;
  }

  .phoneSim {
    max-width: 100vw;
  }

  .container {
    font-family: "20dbregular";
    height: 100vh;
    grid-template-columns: 100vw;
    grid-template-rows: 25vh 75vh;
    grid-template-areas:
      "B"
      "A";
  }

  .item {
    border-style: 3px;
    font-size: 1em;
  }

  .item.a {
    grid-area: A;
    width: 90vw;
    display: flex;
    justify-content: center;
    border-left: none;
    border-top: none;
  }

  .item.b {
    grid-area: B;
    height: fit-content;
    display: flex;
    left: 0;
    text-align: center;
    border-bottom: solid white;
  }

  .item.c,
  .item.d,
  .item.e,
  .item.f,
  .item.g,
  .item.h {
    visibility: hidden;
  }
}