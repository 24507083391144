.aboutPage {
  height: 100vh;
  min-height: fit-content;
  display: flex;
  font-family: acumin-pro, sans-serif;
  background-color: black;
  background-image: url('../../Images/Photo/flipped-nyc-skyline.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: hard-light;
  border-bottom: solid white;
}

.contact-a {
  width: 40vw;
  padding: 1em;
  font-family: 'Courier New', Courier, monospace, sans-serif;
  color: white;
  font-weight: lighter;
  font-size: 1.5em;
  top: 0;
}

.mainContent-a {
  width: 60vw;
  text-align: justify;
  font-size: 1em;
  padding-left: 2em;
  padding-bottom: 2em;
}

/* I am + brief*/
.IAM-a {
  margin-top: 1em;
  color: white;
  font-family: "20dbregular";
}

.brief-a {
  padding-right: 2em;
  color: var(--greenPants);
  font-style: italic;
  font-weight: 200;
  font-size: 1.5em;
}

/* button */
.downloadResume {
  top: 0;
  height: 5em;
  background-color: black;
  color: var(--greenPants);
  padding: 1em;
  text-align: center;
  text-decoration: none;
  border-color: var(--greenPants);
  border-width: .3em;
  border-radius: 1em;
}

.downloadResume:hover {
  color: white;
  border-color: white;
}

.interactions {
  display: flex;
  align-items: center;
  width: 50vw;
}

/* social icons */
.githubLogo,
.linkedinLogo {
  width: 2em;
  padding-left: 1em;
}

.githubLogo:hover,
.linkedinLogo:hover {
  background-color: var(--greenPants);
  border-radius: 1em;
}

.socialicons {
  display: flex;
  width: 2em;
}

/* bottom contact*/
.bottomContact {
  visibility: hidden;
  width: 0vw;
}

/* for mobile and smaller screens 600px' view*/
@media only screen and (max-width: 850px) {
  .aboutPage {
    width: 90vw;
    height: fit-content;
    display: inline-block;
    padding: 1em;
    background-size: 90vh;
    background-blend-mode: normal;
  }

  .contact-a {
    width: 80vw;
  }

  .topContact {
    visibility: hidden;
  }

  .bottomContact {
    visibility: visible;
  }

  .mainContent-a {
    width: 80vw;
    padding-left: 5vw;
    font-size: .8em;
  }

  .brief-a {
    padding-left: 0;
  }

  p {
    font-size: smaller;
  }

}