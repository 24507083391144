@font-face {
  font-family: '20dbregular';
  src: local("");
  src: url('./Components/Fonts/20db-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

:root {
  --greenPants: #00B14C;
}

body {
  margin: 0;
  background-color: var(--greenPants);
}

.footer {
  display: flex;
  justify-content: left;
  max-width: 100vw;
  height: 8vh;
  background-color: black;
  font-family: acumin-pro, sans-serif;
  font-style: italic;
  color: white;
  justify-content: space-evenly;
  font-size: 1em;
  padding-left: 5vw;
  padding-right: 5vw;
  border-top: solid var(--greenPants);
  border-bottom: solid black;
}

.work-f,
.about-f,
.landing-f,
.privacy-policy-f {
  color: var(--greenPants)
}

/* scroll bar width */
::-webkit-scrollbar {
  width: .5em;
}

/* Track */
::-webkit-scrollbar-track {
  margin-top: 1em;
  margin-bottom: 1em;
  background: var(--greenPants);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: .2em;
  background: white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

@media only screen and (max-width: 850px) {
  .footer {
    display: block;
    justify-content: left;
    height: fit-content;
    background-color: black;
    font-family: acumin-pro, sans-serif;
    font-style: italic;
    color: white;
    font-weight: 200;
    justify-content: space-evenly;
    padding-left: 2em;
    border-top: solid var(--greenPants);
  }
}